import { useState } from "react";
import { Card, Modal, Button } from "react-bootstrap";

function Suggestion(){
    const [suggestList, setSuggestList] = useState([
        {
            title:'LeiaPix Converter',
            highlight:'2D 이미지를 아름다운 3D Lightfield 이미지로 즉시 변환할 수 있는 도구',
            desc:`Leia Pix Converter를 사용하면 2D 영상을 아름다운 3D 라이트필드 영상으로 즉시 변환할 수 있습니다. Leia Image Format, Side-By-Side 3D, Depth Map 또는 Lightfield Animation으로 내보냅니다. 라이트필드로 업그레이드할 이미지를 업로드합니다.`,
            link:'https://convert.leiapix.com/?ref=codingmilitary.com',
            image:'https://firebasestorage.googleapis.com/v0/b/cmaai-8794c.appspot.com/o/thumbnail2%2F2138.jpg?alt=media'
        },
        {
            title:'tubebuddy',
            highlight:'YouTube 채널을 위한 종합 AI 지원 툴, 유명 유튜버들은 모두 사용하고 있다는 채널 최적화 및 SEO 개선 서비스',
            desc:`TubeBuddy는 YouTube 제작자와 브랜드가 조회수와 구독자 증가를 위해 채널을 최적화할 수 있도록 설계된 도구입니다. 이는 사용자가 더 많은 검색에 표시될 완벽한 키워드를 찾고, 게시 시간을 절반으로 줄이고, 검색 결과에서 더 높은 순위를 매기고, 채널 성과를 높이고, 성공적인 썸네일을 만드는 데 도움이 됩니다. TubeBuddy의 사명은 YouTube 제작자와 나머지 YouTube 커뮤니티를 더 행복하고 생산적으로 만드는 것입니다.
            
            많은 유명 유튜버들은 벌써 도입 후 사용중인 화제의 서비스`,
            link:'https://www.tubebuddy.com/pricing?a=codingmilitary',
            image:'https://firebasestorage.googleapis.com/v0/b/cmaai-8794c.appspot.com/o/thumbnail2%2Fyoutubeai.png?alt=media&token=d1904c3e-3db4-444d-bbe9-b45be8d49d6b'
        },{
            title:'Mixo',
            highlight:'원하는 웹사이트 컨셉을 말하면 바로 만들어주는 AI 웹 생성기',
            desc:`Mixo는 사용자가 몇 초 안에 웹사이트를 쉽게 시작할 수 있게 해주는 AI 기반 플랫폼입니다. 간단한 아이디어 설명으로 Mixo는 AI를 활용하여 웹사이트를 생성하고, 코드가 필요 없는 놀라운 랜딩 페이지를 제공하여 구독자 수집을 시작합니다. 아이디어 출시, 성장 및 테스트를 위한 기본 제공 이메일 대기 목록과 필수 도구를 제공합니다. 사용자는 쉽게 사이트를 맞춤화하고, 이메일, 설문조사, 인터뷰를 통해 고객과 소통하고, 제품 아이디어를 검증할 수 있습니다. 통합된 구독자 관리 도구는 잠재고객 증가를 촉진하여 마케팅 플랫폼으로 내보내고 Google Analytics를 통한 심층 추적을 가능하게 합니다. 450,000명 이상의 제작자가 신뢰하는 Mixo는 신뢰성, 품질 및 혁신적인 기능으로 호평을 받고 있습니다.`,
            link:'https://mixo.io/?via=codingmilitary',
            image:'https://firebasestorage.googleapis.com/v0/b/cmaai-8794c.appspot.com/o/thumbnail2%2Fmixoai.png?alt=media&token=9f69573f-0cf0-4bd0-832d-74009038374b'
        }
    ]);


    const [show, setShow] = useState(false);
    const [modalImageData, setModalImageData] = useState(null)
    const [modalTitleData, setModalTitleData] = useState(null)
    const [modalHighlightData, setModalHighlightData] = useState(null)
    const [modalDescData, setModalDescData] = useState(null)
    const [modalLinkData, setModalLinkData] = useState(null)
    const handleClose = () => setShow(false);
    const handleShow2 = (_) => {

        setModalImageData(_.image)
        setModalTitleData(_.title)
        setModalHighlightData(_.highlight)
        setModalDescData(_.desc)
        setModalLinkData(_.link)
        setShow(true);
    }
    return (
        <div className="my-3 p-3 bg-body rounded shadow-sm">
            {/* <h5 className="pb-2 mb-0">지금 핫한 AI 툴</h5> */}
            <h6 className="border-bottom pb-2 mb-0">🔻 사람들이 많이 찾는 바로 그 툴!은 아래에 🔻</h6>
            
                {suggestList.map((d,i) => {
                    return <div key={i + 9999} className="d-flex text-muted pt-3">
                        <Card.Img className='hot-image-content image-content' variant="top" src={d.image} />

                        <div className="pb-3 mb-0 small lh-sm border-bottom w-100" style={{marginLeft:5}}>
                            <div className="d-flex justify-content-between">
                            
                            <span className="text-gray-dark">{d.highlight}</span>
                           
                            </div>
                            <Button style={{float:"right"}} variant="danger" size="sm" onClick={() => handleShow2(d)}>자세히 보기</Button>
                            <strong className="d-block">{d.title}</strong>
                        </div>
                    </div>
                })}
                <iframe className="pang-suggest" src="https://ads-partners.coupang.com/widgets.html?id=754952&template=carousel&trackingCode=AF0121908&subId=&width=680&height=140&tsource=" width="680" height="140" frameBorder="0" scrolling="no" referrerPolicy="unsafe-url"></iframe>

            
            
            {/* <small className="d-block text-end mt-3">
            <a href="#">All suggestions</a>
            </small> */}
            <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>지금 핫한 서비스</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card.Img className='image-content' variant="top" src={modalImageData} style={{borderRadius:7}}/>
                <Card.Title className="card-font" style={{marginTop:10}}>{modalTitleData}</Card.Title>
                <br/>
                <Card.Text>
                    <span style={{fontSize:17}}>{modalHighlightData}</span> <br/><br/>
                    <span style={{fontSize:17}}>{modalDescData}</span> <br/>
                </Card.Text>
                <iframe className="pang-suggest" src="https://ads-partners.coupang.com/widgets.html?id=754952&template=carousel&trackingCode=AF0121908&subId=&width=680&height=140&tsource=" width="680" height="140" frameBorder="0" scrolling="no" referrerPolicy="unsafe-url"></iframe>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                닫기
            </Button>
            <Button variant="warning" onClick={() => {
                window.location.href = modalLinkData
            }}>
                서비스 구경하기
            </Button>
            </Modal.Footer>
        </Modal>
        </div>
    )
}

export default Suggestion;