import { useState } from "react";
import { useEffect } from "react";
import { handleApiRequest } from "../api/getListData";
import ItemList from "../components/itemList";
import ItemNavItem from "../components/ItemNavItem";
import Suggestion from "../components/suggestion";
import { categoryList, categoryListEn } from "../api/getCategory";
import { Badge, Button, Card, Form, InputGroup, Offcanvas } from "react-bootstrap";
const defaultPage = 10
function List() {

    const [globalData, setGlobalData] = useState([])
    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [currentData, setCurrentData] = useState([])
    const [keyword, setKeyword] = useState('')
    const [goTitle, setGoTitle] = useState('자세히보기')

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    const doSearch = () => {
        let list = [];
        for(let i=0; i<globalData.length; i++){
            let flag = false;
            if(globalData[i].title.indexOf(keyword) > -1){
                flag = true
            }
            if(globalData[i].highlight.indexOf(keyword) > -1){
                flag = true
            }
            if(globalData[i].desc.indexOf(keyword) > -1){
                flag = true
            }
            if(flag){
                list.push(globalData[i])
            }
        }
        setData(list)
        setCurrentData(list.slice(0, defaultPage))
    }

    function shuffle(array) {
        return array.sort(() => Math.random() - 0.5);
    }

    const init = async () => {
        let result = JSON.parse(await handleApiRequest());
        result = shuffle(result);
        setGlobalData(result)
        setData(result)
        setCurrentData(result.slice(0, defaultPage))
    }

    const selectCategory = (category) => {
        if(category == 'HOME'){
            setData(globalData)
            setCurrentData(globalData.slice(0, defaultPage))
        }else{
            let list = [];
            for(let i=0; i<globalData.length; i++){
                if(globalData[i].tag.includes(category)){
                    list.push(globalData[i])
                }
            }
            setData(list)
            setCurrentData(list.slice(0, defaultPage))
        }

    }

    useEffect(()=>{
        init()
    },[])

    // 아이템 로드 함수
    const loadMoreItems = () => {
        // 다음 10개 아이템을 로드
        const nextItems = data.slice(currentData.length, currentData.length + defaultPage);
        setCurrentData([...currentData, ...nextItems]);
    };
    // 스크롤 이벤트 핸들러
    const handleScroll = () => {
        if (Number(document.documentElement.offsetHeight) - Number(window.innerHeight + document.documentElement.scrollTop) < 50){
            loadMoreItems();
        }else{
            return;
        }
      
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, [currentData.length]);

    return (
        <div>
            <nav className="navbar navbar-expand-lg fixed-top navbar-dark" style={{background:'#bb2d3b'}} aria-label="Main navigation">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">
                    개발자사관학교 AI.mode</a>
                    <button className="navbar-toggler p-0 border-0" type="button" id="navbarSideCollapse" aria-label="Toggle navigation" onClick={handleShow}>
                    <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
                    
                    {/* <form className="d-flex">
                        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
                        <button className="btn btn-outline-success" type="submit">Search</button>
                    </form> */}
                    </div>
                </div>
            </nav>

            {/* <div className="nav-scroller bg-body shadow-sm">
                <nav className="nav nav-underline" aria-label="Secondary navigation">
                   <a style={{color:"#333"}}>개발 강의, GPT 최신 기술 전자책 정보는 여기서</a>
                </nav>
            </div> */}

            <main className="container">
                <div className="d-flex align-items-center p-3 my-3 text-white bg-purple rounded shadow-sm">
                    {/* <img className="me-3" src="/docs/5.0/assets/brand/bootstrap-logo-white.svg" alt="" width="48" height="38"/> */}
                    <div className="lh-1">
                    <h1 className="h5 mb-0 text-white lh-1">총 {globalData.length} 개의 AI 서비스가 여러분들을 기다리고 있습니다. 매주 최신 기술 업데이트중...</h1>
                    <br/>
                    <Badge bg="danger" style={{padding:5,margin:3,fontSize:15}}>
                    <a className="nav-link active" aria-current="page" href="#" onClick={() => selectCategory('HOME')}>전체보기</a>
                    </Badge>
                    {categoryList.map((d,i) => {
                        return (
                            <Badge key={"c"+i} bg="dark" style={{padding:5,margin:3,fontSize:15}}>
                            <a  className="nav-link" href="#" onClick={() => {
                                selectCategory(categoryListEn[i])
                            }}>{d}
                                <span className="badge bg-light text-dark rounded-pill align-text-bottom"></span>
                            </a>
                            </Badge>
                            
                        )
                    })}
                    {/* <Button variant="danger" onClick={handleShow}>전체 카테고리 보기</Button> */}
                    <br/>     <br/>
                    <InputGroup className="mb-3">
                        <Form.Control
                        placeholder="원하는 서비스관련 키워드를 입력해보세요"
                        aria-label="원하는 서비스관련 키워드를 입력해보세요"
                        aria-describedby="basic-addon2"
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                        />
                        <Button variant="danger" id="button-addon2" onClick={() => doSearch()}>
                         키워드 검색
                        </Button>
                    </InputGroup>
                    </div>
                </div>
                <Suggestion/>
                <ItemList currentData={currentData}/>
            </main>
            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                <Offcanvas.Title>개발자사관학교 AI.mode</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                <p>요즘 너무 많은 AI 서비스들이 많아서 머리 아프셨죠? <br/>원하는 서비스의 키워드, 카테고리를 검색해서 빠르게 찾아보세요. 매주 새로운 AI 툴/서비스들을 업데이트 하고 있습니다.</p>
                <p>제휴문의: hello_cma@naver.com</p>
                <br/>
                <p>이런건 어떠세요? </p>
                <Card>
                    <Card.Body>
                        <Card.Title>실전 GPTs 제작 AtoZ 전자책</Card.Title>
                        <Card.Text>
                        나만의 GPTs 만드는 기초 방법부터, GPT스토어에서 경쟁력을 갖기 위한 API 만들고 연결까지 해보는 실전 GPT강의
                        </Card.Text>
                        <Button size="sm" variant="danger" style={{float:"right"}} onClick={() => window.location.href = 'https://cma.liveklass.com/'}>
                        {goTitle}</Button>
                    </Card.Body>
                </Card>
                <Card style={{marginTop:5}}>
                    <Card.Body>
                        <Card.Title>앱 개발 속성 과외 받고 싶다면?</Card.Title>
                        <Card.Text>
                        수익형 앱개발 및 배포까지 1:1로 지도 받고 싶다면, 남들보다 몇개월 빨리 앞서서 앱개발을 완성하고 싶다면! 최초 개발자 개인 교습 받으세요
                        </Card.Text>
                        <Button size="sm" variant="danger" style={{float:"right"}}
                        onClick={() => window.location.href = 'https://cma.liveklass.com/classes/154512'}>
                        {goTitle}</Button>
                    </Card.Body>
                </Card>
                <Card style={{marginTop:5}}>
                    <Card.Body>
                        <Card.Title>웹 개발 프론트 강의</Card.Title>
                        <Card.Text>
                        영화 리뷰 사이트를 화면을 만들며 배우는 기초 프론트 강의
                        </Card.Text>
                        <Button size="sm" variant="danger" style={{float:"right"}}
                        onClick={() => window.location.href = 'https://101creator.page.link/r3SN'}
                        >{goTitle}</Button>
                    </Card.Body>
                </Card>
                <Card style={{marginTop:5}}>
                    <Card.Body>
                        <Card.Title>웹 개발 백엔드 강의</Card.Title>
                        <Card.Text>
                        영화 리뷰를 남기는 서버 기능을 만들며 배우는 기초 서버 강의
                        </Card.Text>
                        <Button size="sm" variant="danger" style={{float:"right"}}
                         onClick={() => window.location.href = 'https://101creator.page.link/r3SN'}
                        >{goTitle}</Button>
                    </Card.Body>
                </Card>
                <Card style={{marginTop:5}}>
                    <Card.Body>
                        <Card.Title>웹 사이트 배포 강의</Card.Title>
                        <Card.Text>
                        영화 리뷰 사이트에 최종적으로 도메인 구매 후 연결까지! 실제 사이트 배포 및 구글 분석 툴까지 연결하는 현업 기초 강의 
                        </Card.Text>
                        <Button size="sm" variant="danger" style={{float:"right"}}
                         onClick={() => window.location.href = 'https://101creator.page.link/r3SN'}
                        >{goTitle}</Button>
                    </Card.Body>
                </Card>
                
                <Card style={{marginTop:5}}>
                    <Card.Body>
                        <Card.Title>더 알고 싶다 개발자사관학교!</Card.Title>
                        <Card.Text>
                        개발자사관학교의 개발 방법론과 교육 방침, 그리고 개발로 창업한 사례들을 같이 탐구해보고 싶다면 블로그로 오세요!
                        </Card.Text>
                        <Button size="sm" variant="danger" style={{float:"right"}}
                        onClick={() => window.location.href = 'https://blog.naver.com/coding_military_acdm/223236515382'}>{goTitle}</Button>
                    </Card.Body>
                </Card>
                
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default List;