import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import List from './pages/List';
import ReactGA from "react-ga4";
import { useEffect } from 'react';


function App() {

  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
      ReactGA.initialize("G-ZS071YZDBR");
    }
  }, []);

  // location 변경 감지시 pageview 이벤트 전송
  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.send("pageview");
  }, []);
  return (
    <List/>
  );
}

export default App;
