
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Badge, Button, Card, Modal, Spinner } from 'react-bootstrap';
import { useState } from 'react';



function ItemList({currentData}){
    const [show, setShow] = useState(false);
    const [modalImageData, setModalImageData] = useState(null)
    const [modalTitleData, setModalTitleData] = useState(null)
    const [modalHighlightData, setModalHighlightData] = useState(null)
    const [modalDescData, setModalDescData] = useState(null)
    const [modalLinkData, setModalLinkData] = useState(null)
    const handleClose = () => setShow(false);
    const handleShow = (_) => {

        setModalImageData(`https://firebasestorage.googleapis.com/v0/b/cmaai-8794c.appspot.com/o/thumbnail2%2F${_.idx}.jpg?alt=media`)
        setModalTitleData(_.title)
        setModalHighlightData(_.highlight)
        setModalDescData(_.desc)
        setModalLinkData(_.link)
        setShow(true);
    }


return currentData.length !== 0 ? (
    <div className="my-3 p-3 bg-body rounded shadow-sm">

         <Row xs={1} md={3} className="g-4">
            {currentData.map((_, idx) => (
                <Col key={_.idx + idx}>
                <Card>
                    <div className='image-container'>
                        <Card.Img className='image-content' variant="top" src={`https://firebasestorage.googleapis.com/v0/b/cmaai-8794c.appspot.com/o/thumbnail2%2F${_.idx}.jpg?alt=media`}/>
                        <div style={{float:'right',position:"absolute",right:10,top:5}}>
                            {_.tag.map((m,i) => {
                                return <Badge key={i + idx} bg="warning" text="dark">
                                {m} {' '}
                            </Badge>
                            })}
                        </div>
                    </div>
                  
                    <Card.Body className="card-line">
                        <Card.Title className="card-font">{_.title}</Card.Title>
                        <Card.Text>
                            <span style={{fontSize:17}}>{_.highlight}</span> <br/>
                           
                           
                        </Card.Text>
                        <Button variant="danger" onClick={() => handleShow(_)}>자세히 보기</Button>
                    </Card.Body>
                </Card>
                </Col>
            ))}
        </Row>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>지금 핫한 서비스</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card.Img className='image-content' variant="top" src={modalImageData} style={{borderRadius:7}}/>
                <Card.Title className="card-font" style={{marginTop:10}}>{modalTitleData}</Card.Title>
                <br/>
                <Card.Text>
                    <span style={{fontSize:17}}>{modalHighlightData}</span> <br/><br/>
                    <span style={{fontSize:17}}>{modalDescData}</span> <br/>
                </Card.Text>
  
                <iframe className="pang-suggest" src="https://ads-partners.coupang.com/widgets.html?id=758237&template=carousel&trackingCode=AF0121908&subId=&width=680&height=140&tsource=" width="680" height="140" frameborder="0" scrolling="no" referrerpolicy="unsafe-url" browsingtopics></iframe>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                닫기
            </Button>
            <Button variant="warning" onClick={() => {
                window.location.href = modalLinkData
            }}>
                서비스 구경하기
            </Button>
            </Modal.Footer>
        </Modal>
  </div>

):<Container style={{textAlign:'center'}}>
    <Spinner animation="grow" variant="secondary" />
    <Spinner animation="grow" variant="success" />
    <Spinner animation="grow" variant="danger" />
    <span>2500여개의 AI 서비스 로딩중</span>
    <Spinner animation="grow" variant="danger" />
    <Spinner animation="grow" variant="warning" />
    <Spinner animation="grow" variant="info" />
</Container>}

export default ItemList;