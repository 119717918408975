import CryptoJS from 'crypto-js';


const decrypt = (encryptedData) => {
    const secretKeyString = '9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08'; // 서버와 동일한 키
    const key = CryptoJS.enc.Hex.parse(secretKeyString);
    const iv = CryptoJS.enc.Hex.parse(encryptedData.iv);

    const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: CryptoJS.enc.Hex.parse(encryptedData.content) }, 
        key, 
        { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );

    return decrypted.toString(CryptoJS.enc.Utf8);
};
export const handleApiRequest = async () => {
  
   
    try {
        const getLocalStorageTime = await window.localStorage.getItem('getListDataTime');
        const currentTime = new Date();
        if(getLocalStorageTime){
         
            const difference = currentTime.getTime() - Number(getLocalStorageTime);
            const minutesDifference = Math.floor(difference / 60000);
            const data = await window.localStorage.getItem('_d');
            if(minutesDifference > 10 || !data){
                // console.log(">>> (1) data")
                await window.localStorage.setItem('getListDataTime',currentTime.getTime());
                return await onCallListData();
            }else{
                // console.log(">>> (2) data")
                return JSON.parse(decrypt(JSON.parse(data)));
            }
        }else{
            await window.localStorage.setItem('getListDataTime', currentTime.getTime());
            return await onCallListData();
        }

    } catch (error) {
        console.log(error)
        console.error('API 요청 중 오류 발생:', error);
    }
};

const onCallListData = async () => {
    const url = 'https://us-central1-cmaai-8794c.cloudfunctions.net/getAiToolResult'; // 실제 API URL로 변경하세요
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
    }
    const result = await response.json();
    const encryptedData = JSON.stringify(result.resultData);
    await window.localStorage.setItem('_d',encryptedData);
    const data = JSON.parse(decrypt(result.resultData));
   
    return data
}