export const categoryList = [
    "마케팅",
    "생성 예술",
    "생산성",
    "채팅",
    "카피라이팅",
    "연구",
    "음성 변조",
    "생성 비디오",
    "AI 감지",
    "생성 코드",
    "번역",
    "비디오 편집",
    "금융",
    "소셜 미디어",
    "재미를 위해",
    "팟캐스팅",
    "텍스트-투-비디오",
    "이미지 개선",
    "자기 개발",
    "아바타",
    "음악",
    "음성-투-텍스트",
    "모션 캡처",
    "텍스트-투-스피치",
    "게이밍",
    "집계자",
    "프롬프트 가이드",
    "특별 제안",
    "맷의 추천",
    "이미지 스캐닝",
    "영감"
];
export const categoryListEn = [
    "Marketing",
    "Generative Art",
    "Productivity",
    "Chat",
    "Copywriting",
    "Research",
    "Voice Modulation",
    "Generative Video",
    "AI Detection",
    "Generative Code",
    "Translation",
    "Video Editing",
    "Finance",
    "Social Media",
    "For Fun",
    "Podcasting",
    "Text-To-Video",
    "Image Improvement",
    "Self-Improvement",
    "Avatar",
    "Music",
    "Speech-To-Text",
    "Motion Capture",
    "Text-To-Speech",
    "Gaming",
    "Aggregators ",
    "Prompt Guides",
    "Special Offer",
    "Matt's Picks",
    "Image Scanning",
    "Inspiration"
]
